<!-- 推广用户列表 2022/5/7 -->
<template>
  <div class="promote-users-container">
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="nav-page-container">
      <div class="search-level-container">
        <div
            v-for="level in levelSearchList"
            :key="level.level"
            :class="{ checked: level.level === searchForm.level }"
            @click="query(level.level)"
        >
          {{ level.name }}
        </div>
      </div>
      <page-container class="promote-users-scroller">
        <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            style="position: relative;z-index: 1"
        >
          <template #loading>
            {{ pagination.pageNum < 2 ? '' : '加载中...' }}
          </template>

          <!-- 更多列表加载 -->
          <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedTextRender()"
              @load="onLoad"
          >
            <!-- 下级推广用户 -->
            <div
                v-for="{ avatar, nickname } of dataList"
                class="user-container"
            >
              <van-image :src="avatar"/>
              <div>{{ nickname }}</div>
            </div>
          </van-list>

        </van-pull-refresh>
      </page-container>
    </page-container>
  </div>
</template>

<script>
import { getChildUser } from '@/api/user';

export default {
  name: 'promoteUsers',
  data() {
    return {
      levelSearchList: [
        { name: '会员', level: 1, },
        { name: '居间', level: 2, },
        { name: '推广员', level: 3, },
      ],

      searchForm: { level: 1 },

      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getChildUser({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    finishedTextRender() {
      let { loading, finished, dataList } = this;
      if (!loading && finished) {
        return dataList.length ? '没有更多了' : '暂无相关数据';
      }
    },

    query(level) {
      this.searchForm = { level };
      this.refreshing = true;
      this.onRefresh();
    },
  },
};
</script>

<style lang="less" scoped>
.promote-users-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  @search-level-container-height: 40px;

  .search-level-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: @search-level-container-height;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;
      font-size: 14px;
      color: #555;
      text-align: center;
      transition: all .33s;

      &.checked {
        position: relative;
        font-size: 16px;
        font-weight: bolder;
        color: @primary-color;
      }
    }
  }

  .promote-users-scroller {
    top: @search-level-container-height;

    .user-container {
      display: flex;
      align-items: center;
      margin: 10px;
      padding: 10px;
      font-size: 14px;
      color: #333;
      background-color: #fff;
      border-radius: 10px;

      .van-image {
        flex-shrink: 0;
        margin-right: 12px;
        width: 68px;
        height: 68px;
      }
    }


  }
}
</style>
